
<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Token",
  methods: {
    ...mapActions("auth", ["login"]),
  },
  async created() {
    var query_token = this.$route.query;
    await this.login(query_token);
    this.$router.push("/");
  },
};
</script>